const ans_imageQuestion = {
  setting: {
    type: 'ans_images',
    allowChangeVote: false,
    slideStartVoting: true,
    playAfterClicking: false,
    showInLoop: false,
    exitGame: false,
    correctlyAnsweredBefore: false,
    firstClicker: false,
    answerIsSequenceClicks: false,
    fullscreen: false,
    scoringReduction: {
      active: false,
      seconds: '',
      score: '',
    },
    automaticSkip: {
      active: false,
      seconds: '',
    },
    slidBackgroundMedia: {
      src: '',
      format: '',
    },
  },
}
export default ans_imageQuestion
