import axios from 'axios'
export default {
  state: {
    GameResults: [],
    'gameResults/filtered': [],
    GameResult: null,
  },
  getters: {
    GameResults: state => state.GameResults,
    GameResult: state => state.GameResult,
    'gameResults/filtered': state => state['gameResults/filtered'],
  },
  mutations: {
    'gameResults/set': (state, payload) => {
      state.GameResults = payload
      state.filtered = [...state.GameResults]
    },
    'gameResult/set': (state, payload) => (state.GameResult = payload),
    'gameResults/filter': (state, { key, val }) => {
      state['gameResults/filtered'] = !val
        ? [...state.GameResults]
        : state['gameResults'].filter(f => f[key] === val)
    },
    'gameResults/add': (state, payload) => state.GameResults.push(payload),
    'gameResults/remove': (state, id) =>
      (state.GameResults = state.GameResults.filter(item => {
        return item._id !== id
      })),
    'gameResults/update': (state, payload) => {
      state.GameResults = state.GameResults.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    'gameResults/index': async context => {
      try {
        const { data } = await axios.get('/GameResult')
        context.commit('gameResults/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'gameResult/show': async (context, id) => {
      try {
        let { data } = await axios.get('/api/GameResult/' + id)
        context.commit('gameResult/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'gameResult/insert': async (context, payload) => {
      try {
        const formData = new FormData()
        for (var key in payload) {
          formData.append(key, payload[key])
        }
        let { data } = await axios.post('/api/GameResult', formData)
        context.commit('gameResults/add', data)
      } catch (e) {
        console.log(e)
      }
    },
    'gameResult/remove': async (context, id) => {
      try {
        await axios.delete('/api/GameResult/' + id)
        context.commit('gameResults/remove', id)
      } catch (e) {
        console.log(e)
      }
    },
    'gameResult/update': async (context, payload) => {
      try {
        const formData = new FormData()
        for (var key in payload) {
          formData.append(key, payload[key])
        }
        let { data } = await axios.put(
          '/api/GameResult/' + payload._id,
          formData
        )
        context.commit('gameResults/update', data)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
