import axios from 'axios'
import router from '../../router/index'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    isLoggedIn: false,
    name: null,
    role: null,
  },
  getters: {
    adminName: state => state.name,
    adminLoggedIn: state => state.isLoggedIn,
    adminRole: state => state.role,
  },
  mutations: {
    loadAdminName: (state, payload) => (state.name = payload),
    'admin/login': (state, admin) => {
      state.isLoggedIn = true
      state.name = admin.name
      state.role = admin.role
    },
    clearData: state => {
      state.isLoggedIn = false
      state.name = null
      state.role = null
    },
  },
  actions: {
    'admin/login': async (context, payload) => {
      try {
        console.log('trying')
        let { data } = await axios.post('/admin/login', payload)
        context.commit('admin/login', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
        throw new Error(e.response.data)
      }
    },
    'admin/checkLogin': async context => {
      try {
        let { data } = await axios.get('/admin/checkLogin')
        context.commit('admin/login', data)
      } catch (e) {
        throw new Error()
      }
    },
    'admin/logout': async context => {
      context.commit('clearData')
      await axios.post('/admin/logout')
      router.push({ name: 'Login' })
    },
    'admin/addManyUsers': async (context, payload) => {
      try {
        await axios.post('/admin/addManyUsers', payload)
      } catch (e) {
        throw e.response.data
      }
    },
  },
}
