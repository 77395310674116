<template>
  <div>
    <v-row class="d-flex justify-center align-center mb-4">
      <span class="text-h6 ml-4">{{ answerIndex + 1 }}.</span>
      <v-checkbox
        @change="setAns()"
        v-model="form.correct"
        label="תשובה נכונה"
      ></v-checkbox>
      <div class="d-flex align-center mx-2">
        <v-btn
          v-if="answerIndex || answersLength - 1"
          @click="removeAns()"
          class=""
          fab
          x-small
          dark
          color="primary"
          elevation="1"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-row v-if="form.ans">
      <v-col>
        <v-dialog max-width="900" class="v-overlay__content">
          <v-img
            :src="`https://res.cloudinary.com/${cloudName}/image/upload/${form.ans}`"
          />
          <template v-slot:activator="{ on, attrs }">
            <v-card
              :elevation="answerIndex + 1"
              height="200"
              width="200"
              class="white zoom-in"
              :style="`
            background: url('https://res.cloudinary.com/${cloudName}/image/upload/${form.ans}')
            no-repeat center center;
            background-size: cover;
            background-color: white;`"
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </v-dialog>
        <v-btn @click="removeAns" color="error" class="mt-2">{{
          $t('מחיקה')
        }}</v-btn>
      </v-col>
    </v-row>

    <v-row v-else class="fill-height" align="center" justify="center">
      <v-col>
        <select-update-format
          :src="form.ans"
          :btnText="$t('תשובה')"
          @save="v => chosenImage(v, 'ansMedia')"
          :format="form.ansMedia.format"
          :deleteText="$t('generics.remove')"
          :noVideo="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import SelectUpdateFormat from '@/components/buttons/SelectUploadFormat.vue'

export default {
  name: 'AnswerMedia',
  components: { SelectUpdateFormat },
  props: {
    answerIndex: Number,
    answersLength: Number,
    canChooseCorrectAns: { type: Boolean, default: true },
    answer: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      form: {
        openImageGallery: false,
        ansMedia: {
          format: '',
          src: '',
        },
        ans: '',
        correct: false,
      },
    }
  },
  computed: {
    rules() {
      return {
        ans: v => !!v || this.$t('errors.notEmpty'),
      }
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
  },
  methods: {
    chosenImage(value, name = 'ansMedia') {
      this.form.ans = value.public_id
        ? value.public_id + '.' + value.format
        : ''
      this.form[name].src = value.public_id
      this.form[name].format = value.format
      this.setAns()
    },
    removeAns() {
      this.$emit('save', { public_id: '', format: '' })
      this.$emit('saveAndUpdate')
      this.$emit('remove', this.answerIndex)
    },

    setAns() {
      this.$emit(
        'setAns',
        _.cloneDeep({
          id: this.answerIndex + 1,
          index: this.answerIndex,
          ...this.form,
        })
      )
    },
  },
  mounted() {
    this.form = { ...this.form, ..._.cloneDeep(this.answer) }
  },
}
</script>

<style scoped>
.zoom-in {
  cursor: zoom-in;
}
</style>
