<template>
  <v-app>
    <!-- <drawer v-if="isLoginPage" v-model="drawer" /> -->
    <v-main v-show="!isLoading">
      <navbar v-if="isLoginPage" @toggleDrawer="toggleDrawer" />
      <router-view />
    </v-main>
    <close-app-footer v-if="showFooter" />
    <loading-page v-if="isLoading" />
    <!-- <SystemNotice v-if="showSystemNotice" v-model="showSystemNotice"/> -->
  </v-app>
</template>

<script>
import { isRtlLang } from './i18n'
import Navbar from '@/components/Navbar'
import CloseAppFooter from './components/CloseAppFooter'
import LoadingPage from './components/LoadingPage.vue'
// import SystemNotice from "./components/SystemNotice.vue";
// import Drawer from "./components/Drawer.vue";

export default {
  name: 'App',
  data: () => ({
    drawer: false,
    showSystemNotice: true,
  }),
  components: {
    Navbar,
    CloseAppFooter,
    LoadingPage,
    // SystemNotice
  },
  computed: {
    showFooter() {
      // return this.$route.path.includes("/admin");
      return !this.isLoading
    },
    isLoginPage() {
      return this.$route.name !== 'Login'
    },
    isLoading() {
      return this.$store.getters.loading.active
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
    await this.$translator.fetchTranslations(this.$i18n);
    // this.$store.dispatch("setting")
  },
  watch: {
    '$i18n.locale': function (newLocale) {
      const isRtl = isRtlLang(newLocale)
      this.$vuetify.rtl = isRtl
    },
  },
}
</script>
