<template>
  <div>
    <my-dialog :title="'ייבוא מיוטיוב'" v-model="openModal">
      <template v-slot:content>
        <v-row
          class="d-flex justify-center align-center"
          v-if="checkboxIfIsYoutubeUrl"
        >
          <v-col cols="8">
            <iframe
              width="560"
              height="315"
              :src="youtubeUrl"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="8">
            <v-text-field
              placeholder="הכנס את הכתובת של יוטיוב"
              v-model="youtubeUrlText"
              @input="loadURL()"
              @keypress.native.enter="loadURL()"
              :error-messages="error"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" v-text="'ייבא'" @click="submit" />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from '@/components/MyDialog.vue'
export default {
  name: 'youtube-upload',
  components: { MyDialog },
  props: {
    value: Boolean,

    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    youtubeUrl: '',
    youtubeUrlText: '',
    result: '',
    err: null,
    isPlaying: false,
    error: '',
  }),
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    checkboxIfIsYoutubeUrl() {
      const regex =
        /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?(?=.*v=((\w|-){11}))(?:\S+)?$/
      return regex.test(this.youtubeUrlText)
    },
  },
  methods: {
    async submit() {
      if (!this.checkboxIfIsYoutubeUrl) {
        this.error = 'אנא הכנס קישור תקין'
        return
      }
      try {
        this.$emit('save', { public_id: this.youtubeUrl, format: 'youtube' })
        this.$emit('saveAndUpdate')
        this.openModal = false
      } catch (e) {
        console.log(e)
      }
    },
    createYoutubeUrl(url) {
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
      const urlSplit = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      const YId = urlSplit[2].split(/[^0-9a-z_/\\-]/i)[0]
      return youtubeEmbedTemplate.concat(YId)
    },
    async loadURL() {
      this.error = ''
      if (!this.checkboxIfIsYoutubeUrl) {
        this.error = 'אנא הכנס קישור תקין'
      } else {
        this.youtubeUrl = this.createYoutubeUrl(this.youtubeUrlText)
      }
    },
  },
}
</script>
