import axios from 'axios'

export default {
  state: {
    setting: {
      cloudName: '',
      uploadPreset: '',
      questionsTypes: [],
      host: '',
      baseUrl: '',
    },
    loading: {
      active: false,
      value: null,
      messages: '',
    },
  },
  getters: {
    uploadPreset: state => state.setting.uploadPreset,
    cloudName: state => state.setting.cloudName,
    questionsTypes: state => state.setting.questionsTypes,
    host: state => state.setting.host,
    baseUrl: state => state.setting.baseUrl,
    loading: state => state.loading,
  },
  mutations: {
    'setting/setSetting': (state, payload) => (state.setting = payload),
    'loading/active': (state, payload) => {
      if (payload) {
        state.loading.active = payload
      } else {
        state.loading.active = payload
        state.loading.value = null
        state.loading.messages = ''
      }
    },
    'loading/setValue': (state, payload) => (state.loading.value = payload),
    'loading/setMessages': (state, payload) =>
      (state.loading.messages = payload),
  },
  actions: {
    'setting/index': async context => {
      const { data } = await axios.get('/setting')
      context.commit('setting/setSetting', data)
      return data
    },
  },
}
