<template>
  <v-dialog
    persistent
    v-if="imageUploadVisible"
    v-model="imageUploadVisible"
    max-width="700"
  >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <div class="uploadImage">
      <v-file-input
        v-show="false"
        v-model="selectedFile"
        accept="image/*"
        @change="uploadImage"
        class="custom-file-input"
      >
      </v-file-input>
      <v-card v-if="!showCropDialog">
        <v-img v-if="image" :src="image.url"></v-img>

        <v-text-field
          v-model="image.name"
          :error-messages="errorMassage"
          v-if="image"
          :label="$t('gallery.imageTitle')"
          class="mx-5"
        />
        <v-card-actions>
          <v-btn v-if="image" icon @click="uploadToServer">
            <v-icon>mdi-check</v-icon></v-btn
          >
          <v-spacer v-if="image"></v-spacer>
          <v-btn v-if="image" icon @click="openCropEditor">
            <v-icon>mdi-crop</v-icon> </v-btn
          ><v-spacer v-if="image"></v-spacer>
          <v-btn icon @click="openInput"> <v-icon>mdi-image</v-icon> </v-btn
          ><v-spacer></v-spacer
          ><v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        class="d-flex flex-column align-center justify-center"
        :class="showCropDialog ? '' : 'hide'"
      >
        <vue-cropper
          v-if="image"
          ref="cropper"
          :src="image.url"
          :aspect-ratio="aspectRatio"
          :auto-crop-area="1"
        ></vue-cropper>
        <v-card-actions>
          <v-btn icon @click="confirmCrop"> <v-icon>mdi-check</v-icon></v-btn>
          <v-btn icon @click="rotateRight">
            <v-icon>mdi-rotate-right</v-icon>
          </v-btn>
          <v-btn icon @click="rotateLeft">
            <v-icon>mdi-rotate-left</v-icon> </v-btn
          ><v-btn v-if="!cropRequired" icon @click="cancelCrop">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'

export default {
  components: {
    VueCropper,
  },
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      selectedFile: null,
      image: null,
      showCropDialog: false,
      aspectRatio: 4 / 3,
      title: '',
      isTextfieldErrorState: false,
      cropRequired: false,
      loading: false,
    }
  },
  methods: {
    close() {
      this.image = null
      this.imageUploadVisible = false
    },
    openInput() {
      document.querySelector('.uploadImage input').click()
    },
    async uploadImage() {
      const file = this.selectedFile
      const reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
      }
      reader.onload = () => {
        const image = {
          url: reader.result,
          name: file.name,
        }
        this.image = image
        // Reset the cropper and update the image source
        this.$nextTick(() => {
          this.$refs.cropper.reset()
          this.$refs.cropper.replace(reader.result)
        })

        this.showCropDialog = true
        this.cropRequired = true
      }
    },
    rotateLeft() {
      this.$refs.cropper.rotate(-90) // Rotates the image left by 90 degrees
    },
    rotateRight() {
      this.$refs.cropper.rotate(90) // Rotates the image right by 90 degrees
    },
    openCropEditor() {
      this.showCropDialog = true
      this.$nextTick(() => {
        this.$refs.cropper.replace(this.image.url)
      })
    },

    cancelCrop() {
      this.showCropDialog = false
      this.$refs.cropper.destroy()
    },
    confirmCrop() {
      const canvasData = this.$refs.cropper.getCroppedCanvas()
      const croppedUrl = canvasData.toDataURL()
      this.image.url = croppedUrl
      this.showCropDialog = false
      this.cropRequired = false
      this.$refs.cropper.destroy()
    },
    // Your existing methods...

    async uploadToServer() {
      if (!this.image?.name) {
        this.isTextfieldErrorState = true
        return
      } else {
        this.isTextfieldErrorState = false
      }
      this.loading = true
      // Convert Base64 string to ArrayBuffer
      const base64String = this.image.url.split(',')[1]
      const binaryString = window.atob(base64String)
      const byteArray = new Uint8Array(binaryString.length)
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i)
      }
      const buffer = byteArray.buffer

      // Create a File object from the ArrayBuffer
      const file = new File([buffer], 'filename.ext', {
        type: 'application/octet-stream',
      })

      const formData = new FormData()
      formData.append('url', file)
      formData.append('title', this.image.name)

      // Make a POST request to the server using Axios
      const { data: newImage } = await axios.post('/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.$store.commit('image/unshiftPop', newImage)
      this.image = null
      this.loading = false
      this.imageUploadVisible = false
    },
  },
  computed: {
    errorMassage() {
      return this.isTextfieldErrorState ? 'אנא הכנס כותרת' : ''
    },
    imageUploadVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
<style lang="scss">
.uploadImage {
  .hide {
    display: none !important;
  }
  // .custom-file-input {
  //   margin-top: 0;
  //   padding-top: 0;
  //   width: 24px;
  // }
  // .custom-file-input .v-input__prepend-outer {
  //   width: 100%;
  // }
  // .custom-file-input .v-input__control {
  //   display: none;
  // }
}
</style>
<!--
import express from 'express'
import multer from 'multer'
import imagemin from 'imagemin'
import imageminPngquant from 'imagemin-pngquant'
import cors from 'cors'
const app = express()
const PORT = 3000

app.use(express.json({ limit: '10mb' }))
app.use(cors())
// Configure multer to handle form data
const upload = multer()

app.post('/compress', upload.single('image'), async (req, res) => {
	try {
		const buffer = Buffer.from(req.file.buffer, 'base64')

		const compressedData = await imagemin.buffer(buffer, {
			plugins: [imageminPngquant({ quality: [0.6, 0.8] })],
		})

		res.set('Content-Type', 'image/png')
		res.send(compressedData)
	} catch (error) {
		console.error(error)
		res.status(500).send('Image compression failed.')
	}
})

app.listen(PORT, () => {
	console.log(`Server listening on port ${PORT}`)
}) -->
