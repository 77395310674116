<template>
  <v-row justify="start">
    <!-- <div class="ml-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="showSettingHandler = true"
            outlined
            color="green"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="ml-1">mdi-cog-outline</v-icon>
            {{ $t("setting.setting") }}
          </v-btn>
        </template>
        <span>{{ $t("setting.setting") }}</span>
      </v-tooltip>
    </div> -->
    <div class="ml-2">
      <tooltip-btn
        @action="uploadFileOpen = true"
        :tooltipText="$t('setting.clickHereToExpotFromXl')"
        :btnText="$t('setting.importFromXl')"
        outlined
        color="green"
      />
    </div>
    <div class="ml-2">
      <tooltip-btn
        @action="demoGame"
        :tooltipText="
          $t('setting.clickHereTo', { prop: $t('setting.demoGame') })
        "
        outlined
        :btnText="$t('setting.demoGame')"
        color="green"
      />
    </div>
    <div class="ml-2">
      <tooltip-btn
        v-if="isAdmin || payed"
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('setting.export')"
        textClass="white--text"
        color="green"
        @action="exportFile"
      />
      <tooltip-btn
        v-else
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('generics.toPay')"
        textClass="white--text"
        color="green"
        @action="goToPayment"
      />
    </div>
    <div>
      <tooltip-btn
        v-if="!isRemotes && (isAdmin || payed)"
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('generics.showLink')"
        textClass="white--text"
        color="green"
        @action="showLink = true"
      />
    </div>
    <show-link v-if="showLink" v-model="showLink" :link="link" />
    <setting-handler v-if="showSettingHandler" v-model="showSettingHandler" />
    <upload-file
      v-if="uploadFileOpen"
      v-model="uploadFileOpen"
      @handleUpload="importFile"
    />
    <out-to-pay-handler v-if="showOutToPay" v-model="showOutToPay" />
    <download-handler
      v-if="showDownloadHandler"
      v-model="showDownloadHandler"
      :gameId="game._id"
    />
    <my-dialog
      v-model="openBuyDialog"
      :max-width="300"
      :max-height="100"
      :min-height="100"
    >
      <template v-slot:content>
        <div style="display: grid; place-items: center" class="mx-auto">
          <span>🎉</span>
          <span>{{ $t('games.paySuccess') }}</span>
        </div>
      </template>
    </my-dialog>
  </v-row>
</template>

<script>
import UploadFile from './UploadFile.vue'
import SettingHandler from './handler/SettingHandler.vue'
import TooltipBtn from './buttons/TooltipBtn.vue'
import SwalMixins from '@/mixins/swal'
// import OutToPayHndler from './handler/OutToPayHndler.vue'
import GenericMixins from '@/mixins/generic'
import ShowLink from './ShowLink.vue'
import DownloadHandler from './download/DownloadHandler.vue'
// import axios from 'axios'
import MyDialog from './MyDialog.vue'

export default {
  components: {
    UploadFile,
    SettingHandler,
    TooltipBtn,
    // OutToPayHandler,
    ShowLink,
    DownloadHandler,
    MyDialog,
  },
  name: 'Setting',
  mixins: [SwalMixins, GenericMixins],

  data() {
    return {
      showSettingHandler: false,
      showDownloadHandler: false,
      showOutToPay: false,
      showLink: false,
      uploadFileOpen: false,
      openBuyDialog: false,
    }
  },
  computed: {
    host() {
      return this.$store.getters.host
    },
    game() {
      return this.$store.getters.game
    },
    user() {
      return this.$store.getters.userDetails
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    isRemotes() {
      return this.$store.getters.game?.setting?.gameType === 'remotes'
    },
    link() {
      const host = process.env.VUE_APP_HOST
      const sourceUrl = `https://orquiz-game.clap.co.il/index.html?`
      const hostId = 1111
      // if (this.clickersExported) {
      return (
        '' +
        sourceUrl +
        'userId=' +
        hostId +
        '&' +
        'game=' +
        host +
        '/game/web-export/' +
        this.game?._id +
        `&client_id=${this.uid}`
      )
      // }
      // return this.$store.getters.isAdmin;
    },
    payed() {
      const { openUsage } = this.$store.getters
      if (openUsage?.[this.game?._id]?.startTime + 10800000 > Date.now())
        return true
      const gamesSubscriptions = this.$store.getters.gamesSubscriptions
      return !!gamesSubscriptions
    },
    uid() {
      return this.$store.getters.uid
    },
    phonesExported() {
      return this.payed && this.game?.setting?.limit?.type === 'phones'
    },
    clickersExported() {
      return this.payed && this.game?.setting?.limit?.type === 'clickers'
    },
  },
  methods: {
    async goToPayment() {
      window.location.href = `http://${this.host}/pay/times?gameId=${this.game._id}`
    },
    async demoGame() {
      window.open(this.link + '&demo=true', '_blank')
    },
    async importFile(file) {
      await this.$store.dispatch('upload/xlsx', {
        file,
        gameId: this.game._id,
      })
      this.$emit('setIndex')
    },
    async exportFile() {
      this.showDownloadHandler = true
    },
  },
  watch: {
    async game(value) {
      if (!value?._id) return
      const urlParams = new URLSearchParams(window.location.search)
      const success_payment = urlParams.get('success_payment')
      if (success_payment && this.payed) {
        this.openBuyDialog = true
        //remove success_payment from url
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        )
      }
    },
  },
  // async beforeMount() {
  //   if (this.$route.params.isNew) {
  //     await this.$store.dispatch('game/show', this.$route.params.id)
  //     this.showSettingHandler = true
  //   }
  // },
}
</script>
