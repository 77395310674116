<template>
  <v-container>
    <v-row class="center-all my-5">
      <h1 @click="editMode = true" v-if="!editMode">
        {{ $t('games.name') }} : {{ gameName }}
      </h1>
      <v-text-field
        class="create-game-title"
        v-else
        v-model="title"
        v-click-outside="updateTitle"
      ></v-text-field>
    </v-row>
    <setting />
    <slides-list />
  </v-container>
</template>

<script>
import SlidesList from '@/components/SlidesList.vue'
import Setting from '@/components/Setting'
import SwalMixins from '@/mixins/swal'

export default {
  components: {
    SlidesList,
    Setting,
  },
  name: 'CreateGame',
  mixins: [SwalMixins],
  data() {
    return {
      editMode: false,
      notChange: true,
      currentIndex: 0,
      search: '',
      title: '',
    }
  },
  computed: {
    game() {
      return this.$store.getters.game
    },
    gameName() {
      return this.game?.name
    },
  },
  methods: {
    titleChangeEventHandler(e) {
      if (!this.editMode) return
      if ({ Enter: true, Escape: true }[e.key]) {
        this.updateTitle()
      }
    },
    updateTitle() {
      this.$store.dispatch('game/update', { ...this.game, name: this.title })
      this.editMode = false
    },
    clearData() {
      this.$store.commit('game/clearData')
      this.$store.commit('questions/clearData')
    },
  },
  async beforeMount() {
    try {
      this.clearData()
      this.$store.commit('loading/active', true)
      this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
      if (!this.game) {
        await this.$store.dispatch('game/show', this.$route.params.id)
      }
      if (!this.game) {
        this.$router.push({ name: 'UserDashboard' })
      }
      this.title = this.game.name
      this.$store.commit('loading/active', false)
    } catch (e) {
      console.log(e)
    }
    window.addEventListener('keyup', this.titleChangeEventHandler)
  },
  destroyed() {
    this.clearData()
    window.removeEventListener('keyup', this.titleChangeEventHandler)
  },
}
</script>
<style lang="scss" scoped>
.categories-container {
  width: 500px;
  margin: 0 auto;
}
</style>
<style>
.create-game-title input {
  text-align: center;
}
</style>
