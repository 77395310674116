import mediaStructure from './media'
import surveyStructure from './survey'
import triviaStructure from './trivia'
import ans_imageStructure from './ans_image'

const allFields = obj => {
  let list = []
  for (const [key] of Object.entries(obj)) {
    list.push(key)
  }
  obj['fieldsList'] = list
  return list
}

allFields(mediaStructure.setting)
allFields(surveyStructure.setting)
allFields(triviaStructure.setting)
allFields(ans_imageStructure.setting)

export { mediaStructure, surveyStructure, triviaStructure, ans_imageStructure }
