<template>
  <div
    style="
      background-color: #092c58;
      height: 100vh;
      display: grid;
      place-items: center;
    "
  >
    <div
      class="d-flex flex-column justify-center align-center"
      style="gap: 32px"
    >
      <v-img
        max-width="700"
        src="https://build.orquiz.clap.co.il/assets/logo.png"
      ></v-img>

      <v-btn x-large color="#fff" :href="clapLoginUrl" light height="65px">
        <span style="color: #092c58; font-size: 1.6rem" class="ml-4"
          >התחברות</span
        >
        <v-img
          src="https://build.orquiz.clap.co.il/assets/ClapWay-light.svg"
          max-width="130"
        />
      </v-btn>
    </div>
  </div>
</template>

<script>
import GenericMixins from '@/mixins/generic'
import SwalMixins from '@/mixins/swal'
import axios from 'axios'
export default {
  name: 'Login',
  mixins: [GenericMixins, SwalMixins],
  data: () => {
    return {
      clapLoginUrl: null,
    }
  },
  computed: {},
  methods: {},
  async mounted() {
    const url = await axios.get('auth/clap-login')
    if (url.data?.url) {
      this.clapLoginUrl = url.data?.url
    }
  },
}
</script>
<style lang="scss" scoped></style>
