<template>
  <div>
    <v-row v-if="btnText && format" dense>
      <v-col>
        <div>{{ btnText }}:</div>
      </v-col>
    </v-row>
    <v-row v-if="format && !noPreview" dense>
      <v-col>
        <iframe
          v-if="format === 'youtube'"
          width="100"
          height="100"
          :src="src"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <cld-video
          v-else-if="format === 'mp4'"
          class="center-all"
          :publicId="src"
          :width="400"
          crop="scale"
        />
        <play-audio-cloudinary :publicId="src" v-else-if="format === 'mp3'" />
        <cld-image
          v-else
          class="center-all"
          :publicId="src"
          :width="100"
          crop="scale"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-speed-dial v-model="isOpen" v-if="!src">
          <template v-slot:activator>
            <v-btn v-model="isOpen" color="green" dark>
              <v-icon v-if="isOpen"> mdi-close </v-icon>
              <span v-else>
                {{ btnText }}
                <v-icon> mdi-cloud-upload </v-icon>
              </span>
            </v-btn>
          </template>
          <v-btn @click="openImageGallery = true" color="green" dark>
            {{ $t('generics.mediaUpload') }}
            <v-icon class="pr-2"> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-btn
            v-if="!noVideo"
            @click="openModelYoutube = true"
            color="green"
            dark
          >
            {{ $t('generics.youtubeUpload') }}
            <v-icon class="pr-2"> mdi-youtube </v-icon>
          </v-btn>
        </v-speed-dial>
        <div v-else>
          <!-- class="ma-0" -->
          <v-btn dark small color="error" @click="deleteImageFromCloud">
            {{ $t('generics.remove') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <image-gallery
      @chosen="res => chosenImage(res)"
      hideButton="false"
      v-model="openImageGallery"
    />
    <youtube-upload
      v-model="openModelYoutube"
      @saveAndUpdate="saveAndUpdate"
      @save="save"
    />
  </div>
</template>

<script>
import YoutubeUpload from './YoutubeUpload.vue'
import SwalMixins from '@/mixins/swal'
import PlayAudioCloudinary from '../PlayAudioCloudinary.vue'
import ImageGallery from '@/components/ImageGallery.vue'

export default {
  name: 'select-upload-format',
  props: {
    cloudinaryBtnText: String,
    btnText: String,
    format: String,
    src: String,
    noPreview: { type: Boolean, default: false },
    noVideo: { type: Boolean, default: false },
  },
  components: {
    YoutubeUpload,
    PlayAudioCloudinary,
    ImageGallery,
  },
  mixins: [SwalMixins],
  data() {
    return {
      isOpen: false,
      openModelYoutube: false,
      openImageGallery: false,
    }
  },
  computed: {
    isEmptyMedia() {
      return this.format
    },
    folderName() {
      return process.env.VUE_APP_CLOUD_FOLDER
    },
  },
  methods: {
    chosenImage({ src: public_id, format }) {
      this.$emit('save', { public_id, format })
    },
    loading(v) {
      this.$emit('loading', v)
    },
    openCloud() {
      console.log(this.$refs['cloud-btn'])
      this.$refs['cloud-btn'].uploadFiles()
    },
    save(v) {
      console.log('save', v)
      this.$emit('save', v)
    },
    saveAndUpdate() {
      console.log('saveAndUpdate')
      this.$emit('saveAndUpdate')
    },
    async deleteImageFromCloud() {
      try {
        this.$emit('save', { public_id: '', format: '' })
        this.$emit('saveAndUpdate')
        this.timeoutSwal({ icon: 'success', title: this.$t('images.delete') })
      } catch (e) {
        console.log(e)
        this.timeoutSwal({
          icon: 'error',
          title: this.$t('images.deleteError'),
        })
      }
    },
  },
}
</script>

<style></style>
