import axios from 'axios'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    game: null, //chosen game
    count: 0,
    gameToJSON: null, //chosen gameToJSON
    gameToWeb: null, //chosen gameToJSON
    games: [],
    filtered: [],
  },
  getters: {
    games: state => state.games,
    game: state => state.game,
    gameToJSON: state => state.gameToJSON,
    gameToWeb: state => state.gameToWeb,
    gameFiltered: state => state.filtered,
    gameCount: state => state.count,
  },
  mutations: {
    //sets all games
    'games/set': (state, payload) => {
      state.games = payload
      state.filtered = [...state.games]
    },
    //sets one game
    'game/set': (state, payload) => (state.game = payload),
    'game/unset': state => (state.game = null),
    'gameToJSON/set': (state, payload) => (state.gameToJSON = payload),
    'gameToJSON/unset': state => (state.gameToJSON = null),
    'gameToWeb/set': (state, payload) => (state.gameToWeb = payload),
    'gameToWeb/unset': state => (state.gameToWeb = null),
    //filters the game's array by game's key and game's val
    'games/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.games]
        : state.games.filter(f => f[key] === val)
    },
    //store one game
    'game/store': (state, payload) => state.games.push(payload),
    //destroys one game
    'game/destroy': (state, id) =>
      (state.games = state.games.filter(item => {
        return item._id !== id
      })),
    //updates one game
    'game/update': (state, payload) => {
      state.games = state.games.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
    //store one game
    'game/question/store': (state, payload) =>
      state.game.questions.push(payload),
    'game/question/destroy': (state, id) =>
      (state.game.questions = state.game.questions.filter(item => {
        return item._id !== id
      })),
    //updates one game one question
    'game/question/update': (state, payload) => {
      state.game.questions = state.game.questions.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
    //updates many game one question
    'game/question/storMany': (state, payload) =>
      state.game.questions.push(...payload),
    'game/clearData': state => {
      state.game = null
    },
    'games/clearData': state => {
      state.game = null
      state.games = []
    },
    'game/setCount': (state, payload) => {
      state.count = payload
    },
  },
  actions: {
    //fetch all games
    'game/index': async (
      context,
      { page, perPage, search, sortBy, sortDesc } = {}
    ) => {
      try {
        //url query params
        const params = new URLSearchParams()
        params.append('page', page || 1)
        params.append('perPage', perPage || 25)
        params.append('searchText', search || '')
        params.append('sortBy', sortBy || '')
        params.append('sortDesc', sortDesc)
        const {
          data: { games, count },
        } = await axios.get(`/game?${params.toString()}`)
        context.commit('game/setCount', count)
        context.commit('games/set', games)
        return games
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },

    //fetch one game by id
    'game/show': async (context, id) => {
      try {
        let { data } = await axios.get('/game/' + id)
        context.commit('game/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch my games
    'game/showMany': async context => {
      try {
        const { data } = await axios.get('/game/show-many')
        context.commit('games/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch json for local game
    'game/local-export': async (context, id) => {
      try {
        const { data } = await axios.get('/game/local-export/' + id)
        context.commit('gameToJSON/set', data)
        console.log(data)
        return data
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch json for web game
    'game/web-export': async (context, id) => {
      try {
        const { data } = await axios.get('/game/web-export/' + id)
        context.commit('gameToWeb/set', data)
        console.log(data)
        return data
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //stores one game
    'game/store': async (context, payload) => {
      try {
        let { data } = await axios.post('/game', { ...payload })
        context.commit('game/store', data)
        context.commit('game/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //destroys one game
    'game/destroy': async (context, id) => {
      try {
        await axios.delete('/game/' + id)
        context.commit('game/destroy', id)
        context.commit('game/unset')
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates one game by its id
    'game/update': async (context, payload) => {
      try {
        await axios.put('/game/' + payload._id, payload)
        context.commit('game/set', payload)
        context.commit('game/update', payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //replicate one game by its id
    'game/replicate': async (context, id) => {
      try {
        const { data } = await axios.post('/game/replicate/' + id)
        context.commit('game/store', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates one game by its id
    'game/question/update': async (context, payload) => {
      try {
        context.commit('game/set', payload)
        await axios.put('/game/' + payload._id, payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
