import axios from 'axios'
import PAP from 'papaparse'
import { he as vHb, en as vEn } from 'vuetify/lib/locale'

class Translation {
  defaultLanguage
  translations = {}

  constructor(defaultLanguage) {
    this.defaultLanguage = defaultLanguage
  }

  async fetchTranslations(i18n) {
    try {
      let { data } = await axios.get(process.env.VUE_APP_I18_URL)
      const parsedCSV = PAP.parse(data, { header: false }).data
      parsedCSV.shift()
      const he = parsedCSV.reduce((acc, curr) => {
        acc[curr[0]] = curr[1]
        return acc
      }, {})
      const en = parsedCSV.reduce((acc, curr) => {
        acc[curr[0]] = curr[2]
        return acc
      }, {})
      this.translations = { he, en }

      // Print to console the time and date of the last fetch
      // console.log('translations fetched', new Date().toLocaleString())

      i18n.setLocaleMessage('he', { $vuetify: vHb, ...he })
      i18n.setLocaleMessage('en', { $vuetify: vEn, ...en })
    } catch (error) {
      console.log(error)
    }
  }
}

const TranslationPlugin = {
  install(Vue) {
    Vue.prototype.$translator = new Translation()
  },
}

export default TranslationPlugin
