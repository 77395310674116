<template>
  <div>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="2"> {{ index }}.{{ asset.name }}</v-col>
      <v-col class="d-flex justify-center align-center">
        <v-progress-linear
          v-model="progress"
          :color="progressColor"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="1" class="d-flex justify-center align-center">
        <v-icon v-if="progress >= 100" color="light-green accent-3"
          >mdi-check</v-icon
        >
        <div cols="1" class="d-flex justify-center align-center" v-if="error">
          <v-btn icon>
            <v-icon color="error" @click="removeAsset">mdi-close-thick</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon @click="fetchAsset" color="primary">mdi-reload</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'DownloadRow',
  props: {
    asset: { type: Object, default: () => ({}) },
    index: { type: Number },
    baseUrl: { type: String, default: '' },
  },
  data() {
    return {
      progress: 0,
      error: false,
    }
  },
  computed: {
    url() {
      return `https://res.cloudinary.com/${this.$store.getters.cloudName}/${this.asset.type}/upload/`
    },
    progressColor() {
      if (this.progress < 30) {
        return 'red'
      } else if (this.progress < 60) {
        return 'pink'
      } else if (this.progress < 90) {
        return 'yellow'
      } else {
        return 'light-green accent-3'
      }
    },
  },
  methods: {
    async fetchAsset() {
      try {
        this.error = false
        const { data } = await axios.get(this.url + this.asset.src, {
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            this.progress = percentCompleted
          },
        })
        this.$emit('success', { ...this.asset, data })
      } catch (e) {
        this.error = true
        console.log('error fetch', e)
      }
    },
    removeAsset() {
      this.$emit('removeAsset', this.asset)
    },
  },
  mounted() {
    this.fetchAsset()
  },
}
</script>
<style lang=""></style>
