<template>
  <v-list-item class="list-group-item">
    <span style="cursor: grabbing">
      <v-icon> mdi-drag </v-icon>
    </span>
    <v-list-item-avatar>
      <span class="dark_blue--text text-h5 font-weight-medium">
        {{ index + 1 }}.
      </span>
    </v-list-item-avatar>

    <v-list-item-content style="width: 400px">
      <v-list-item-title v-text="que.question.que" />
      <v-list-item-subtitle v-text="correctAns(que.question.answers)" />
    </v-list-item-content>

    <v-list-item-action>
      <v-row>
        <!-- <v-col class="pa-0">
          <tooltip-icon
            :tooltipText="findType(que).description"
            :icon="findType(que).icon"
            :fab="true"
            color="grey"
          />
        </v-col> -->
        <v-col class="pa-0">
          <tooltip-icon
            :tooltipText="$t('questions.duplicateQuestionTooltip')"
            :icon="'mdi-content-duplicate'"
            :fab="true"
            @action="duplicate(que)"
          />
        </v-col>
        <v-col class="pa-0">
          <tooltip-icon
            :tooltipText="$t('questions.editQuestionTooltip')"
            :icon="'mdi-pencil'"
            :fab="true"
            @action="$emit('edit', { question: que, index: index + 1 })"
          />
        </v-col>
        <v-col class="pa-0">
          <tooltip-icon
            :tooltipText="$t('questions.deleteQuestionTooltip')"
            :icon="'mdi-delete'"
            :fab="true"
            color="error"
            @action="deleteQuestion(que._id)"
          />
        </v-col>
      </v-row>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
// import { typesArray } from "@/components/types/helper";
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import SwalMixins from '@/mixins/swal'

export default {
  name: 'QuestionLine',
  components: { TooltipIcon },
  props: {
    value: Boolean,
    que: { type: Object, default: () => ({}) },
    index: Number,
    clear: Function,
  },
  mixins: [SwalMixins],
  data: () => ({
    // icons: typesArray,
  }),
  methods: {
    // findType(current) {
    //   if (current?.setting?.type) {
    //     return this.icons.find((i) => i.type === current.type);
    //   } else return {};
    // },
    correctAns(answers) {
      const ans = answers?.length
        ? answers.filter((a, i) => {
            const index = i + 1
            if (this.que?.setting?.type === 'ans_images') {
              a['ans_view'] = ' תמונה מספר ' + index
            } else {
              a['ans_view'] = ` תשובה מספר ${index}: ${a.ans}`
            }
            return a.correct
          })
        : []
      return ans.map(a => a.ans_view).toString()
    },
    async duplicate(question) {
      await this.$store.dispatch('question/replicate', {
        id: question._id,
        index: this.index,
      })
    },
    async deleteQuestion(id) {
      const { isConfirmed } = await this.createSwal({
        title: `${this.$t('questions.delete')}?`,
        confirmButtonText: this.$t('generics.remove'),
        denyButtonText: this.$t('generics.cancel'),
        confirmColor: 'red',
        cancelColor: '#2196F3',
      })
      if (isConfirmed) {
        try {
          await this.$store.dispatch('question/destroy', id)
          this.clear()
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>
