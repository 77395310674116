<template>
  <div>
    <div id="myDivMapPick" :class="!show ? 'hide' : ''"></div>
    <select-update-format
      :src="form.markSrcMedia.src || ''"
      @save="v => $emit('save', v)"
      :deleteText="$t('generics.remove')"
      :noPreview="true"
      :noVideo="true"
      :format="form.markSrcMedia"
      v-if="show"
    />
  </div>
</template>
<script>
import { debounce } from 'lodash'
import SelectUpdateFormat from './buttons/SelectUploadFormat.vue'
export default {
  name: 'map-pick',
  components: { SelectUpdateFormat },
  props: {
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    circleColor: { type: String, default: 'hsla(120, 100%, 50%, 0.5)' },
    defaultRadius: { type: Number, default: 20 },
    pointer: { type: Boolean, default: true },
    pointsMethods: { type: Object, default: () => ({}) },
    show: { type: Boolean, default: false },
    form: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      point: null,
      stage: {},
      baseRadius: this.defaultRadius,
    }
  },
  computed: {
    imageAndShow() {
      return this.form.markSrcMedia.src && this.show
    },
    pointComputed() {
      const { point } = this
      return {
        dot_x: point?.x,
        dot_y: point?.y,
        radius: point?.scale ? this.baseRadius * point.scale : point?.radius,
      }
    },
    image() {
      const cloudName = process.env.VUE_APP_CLOUD_NAME
      const srcMedia = this.form.markSrcMedia.src
      const format = this.form.markSrcMedia.format

      return `https://res.cloudinary.com/${cloudName}/image/upload/${srcMedia}.${format}`
    },
  },
  methods: {
    disposeAll() {
      this.pointsArrayLocal.forEach(point => point.dispose())
      this.frame.stage?.update()
    },
    disposeLast() {
      const last = this.pointsArrayLocal.pop()
      if (!last) return
      last.dispose()
      this.frame.stage?.update()
    },
    init() {
      const { radius, dot_x, dot_y } = this.form
      if (radius && dot_x && dot_y) {
        this.point = {
          dot_x,
          dot_y,
          radius,
        }
      }
      const v = this
      v.pointsMethods.disposeAll = v.disposeAll
      v.pointsMethods.disposeLast = v.disposeLast
      const { Frame, Pic, Circle, light } = window
      // See Docs under Frame for FIT, FILL, FULL, and TAG
      v.frame = new Frame({
        scaling: 'myDivMapPick',
        width: v.width,
        height: v.height,
        color: light,
        outerColor: null,
        ready,
        assets: [v.image],
      })
      function ready() {
        // given F (Frame), S (Stage), W (width), H (height)
        // put code here
        new Pic(v.image)
          .scaleTo(window.S, 100, 100)
          .addTo()
          .tap(({ stageX: x, stageY: y }) => {
            // console.log('tap', x, y)
            if (v.point) return
            const circle = new Circle({
              radius: v.defaultRadius,
              color: v.circleColor,
            })
              .loc(x, y)
              .top()
              .transform({ showReg: false })
            if (!v.pointer) return
            new Circle({ radius: 1, color: 'black' })
              .addTo(circle)
              .center()
              .top()
            v.point = circle
          })

        if (
          v.point?.dot_x &&
          v.point?.dot_y &&
          v.point?.radius &&
          !v.point?.scale
        ) {
          v.baseRadius = v.point.radius
          const circle = new Circle({
            radius: v.point.radius,
            color: v.circleColor,
          })
            .loc(v.point.dot_x, v.point.dot_y)
            .top()
            .transform({ showReg: false })
          if (!v.pointer) return
          new Circle({ radius: 1, color: 'black' }).addTo(circle).center().top()
          v.point = circle
        }
        v.frame?.stage?.update()
      }
    },
  },
  watch: {
    imageAndShow(newValue, oldValue) {
      if (!newValue && oldValue) {
        // If show changes from true to false
        this.frame?.dispose()
        this.point = null
      } else if (newValue && !oldValue) {
        // If show changes from false to true
        this.init()
      }
    },
    pointComputed: {
      deep: true,
      handler: debounce(function (v) {
        this.$emit('updatePoint', v)
      }, 500),
    },
  },
  mounted() {
    if (!this.imageAndShow) return
    this.init()
  },
  beforeDestroy() {
    this.frame?.dispose()
  },
}
</script>
<style scoped>
.hide {
  visibility: hidden;
  position: absolute;
}
</style>
