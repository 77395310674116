<template>
  <v-row class="align-center">
    <v-col v-if="!noDragging" cols="1">
      <v-icon large>mdi-drag</v-icon>
    </v-col>
    <v-col class="center-all">
      <span
        class="ark_blue--text text-h6 mx-5"
        :style="`${noNumbers ? 'visibility:hidden' : ''}`"
        >{{ answerIndex + 1 }}.</span
      >
      <v-text-field
        outlined
        @change="setAns()"
        v-model="form.ans"
        :label="`תשובה ${answerIndex + 1}`"
        dense
        hide-details="true"
      ></v-text-field>
    </v-col>
    <v-col cols="2" v-if="canChooseCorrectAns">
      <v-checkbox
        @change="setAns()"
        v-model="form.correct"
        label="תשובה נכונה"
      ></v-checkbox>
    </v-col>
    <v-col cols="2">
      <v-btn
        v-if="answerIndex"
        @click="removeAns()"
        class=""
        fab
        dark
        small
        color="primary"
      >
        <v-icon dark> mdi-minus </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Answer',
  props: {
    answerIndex: Number,
    canChooseCorrectAns: { type: Boolean, default: true },
    ans: { type: String, default: '' },
    correct: { type: Boolean, default: false },
    noNumbers: { type: Boolean, default: false },
    noDragging: { type: Boolean, default: false },
  },

  data() {
    return {
      form: {
        ans: this.ans,
        correct: this.correct,
      },
    }
  },

  watch: {
    ans: function (newAns) {
      this.form.ans = newAns
    },
    correct: function (value) {
      this.form.correct = value
    },
  },
  methods: {
    removeAns() {
      this.$emit('remove', this.answerIndex)
    },
    setAns() {
      this.$emit(
        'setAns',
        Object.assign(
          { id: this.answerIndex + 1, index: this.answerIndex },
          this.form
        )
      )
    },
  },
}
</script>

<style scoped>
.my-label {
  position: absolute;
  right: 40px;
}
</style>
