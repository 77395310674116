<template>
  <div>
    <my-dialog
      :title="
        isNew ? $t('userDetails.addNewUser') : $t('userDetails.editUserDetails')
      "
      v-model="openModal"
    >
      <template v-slot:content>
        <v-row>
          <v-col>
            <v-text-field
              :rules="[rules.emailRequired, rules.validEmail]"
              :label="$t('auth.email')"
              v-model="email"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :label="$t('auth.password')"
              :type="show ? 'text' : 'password'"
              :rules="isNew ? [rules.passRequired, rules.min] : [rules.min]"
              @click:append="show = !show"
              minLength="6"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :label="$t('auth.fullname')"
              v-model="fullname"
              :rules="[rules.nameRequired]"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field :label="$t('auth.phone')" v-model="phone" />
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="!isNew ? $t('generics.change') : $t('generics.add')"
          :disabled="isValidForm"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from '@/components/MyDialog.vue'
import GenericMixins from '@/mixins/generic'
import SwalMixins from '@/mixins/swal'
export default {
  name: 'user-handler',
  components: { MyDialog },
  mixins: [GenericMixins, SwalMixins],

  props: {
    value: Boolean,
    user: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    email: '',
    password: '',
    fullname: '',
    phone: '',
    _id: null,
    show: false,
    err: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    isValidForm() {
      return !(
        this.fullname &&
        this.email &&
        this.emailValidation(this.email) &&
        (this.isNew
          ? this.password && this.password.length >= 6
          : !this.password || this.password.length >= 6)
      )
    },
    rules() {
      return {
        nameRequired: v => !!v || this.$t('errors.notEmpty'),
        emailRequired: v => !!v || this.$t('errors.auth.email'),
        validEmail: v =>
          this.emailValidation(v) || this.$t('errors.auth.emailInvalid'),
        passRequired: v => !!v || this.$t('errors.auth.pass'),
        min: v =>
          v.length === 0
            ? true
            : v.length >= 6 || this.$t('errors.auth.minlen6'),
      }
    },
  },
  methods: {
    async submit() {
      if (this.isNew) {
        await this.$store.dispatch('user/store', {
          fullname: this.fullname,
          email: this.email,
          password: this.password,
          phone: this.phone,
        })
      } else {
        let { isConfirmed } = await this.createSwal({
          icon: 'warning',
          title: `${this.$t('auth.change')} ?`,
        })
        if (!isConfirmed) return

        await this.$store.dispatch('user/update', {
          fullname: this.fullname,
          email: this.email,
          password: this.password ? this.password : undefined,
          phone: this.phone,
          _id: this._id,
        })
        this.timeoutSwal({
          title: this.$t('auth.updateSuccess'),
          icon: 'success',
        })
      }
      this.openModal = false
    },
  },
  mounted() {
    if (!this.isNew) {
      const { fullname, email, phone, _id } = this.user
      this.fullname = fullname
      this.email = email
      this.phone = phone
      this._id = _id
    }
  },
}
</script>
