<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn @click="action" v-bind="$attrs" v-on="on">
          <span :class="textClass">
            {{ btnText }}<v-icon dark v-if="icon">{{ icon }} </v-icon></span
          >
        </v-btn>
      </template>
      <span> {{ tooltipText }} </span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: 'TooltipBtn',
  props: {
    textClass: { type: String, default: '' },
    tooltipText: { type: String, default: '' },
    btnText: { type: String, default: '' },
    icon: { type: String, default: '' },
  },
  methods: {
    action() {
      this.$emit('action')
    },
  },
}
</script>
