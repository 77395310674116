// import "https://stuk.github.io/jszip/dits/jszip.js";
const JSZip = require('jszip')
const { saveAs } = require('file-saver')
const _ = require('lodash')

const downloadMixins = {
  data: function () {
    const zip = new JSZip()
    return {
      zip: zip,
      assets: zip.folder('assets'),
      progress: 0,
    }
  },
  methods: {
    findAllByKey(obj, keyToFind) {
      return Object.entries(obj).reduce(
        (acc, [key, value]) =>
          key.includes(keyToFind)
            ? acc.concat(value)
            : typeof value === 'object' && value
            ? acc.concat(this.findAllByKey(value, keyToFind))
            : acc,
        []
      )
    },
    // findMedia(questions) {
    //   const media = []
    //   questions.forEach((q, i) => {
    //     const index = i + 1
    //     if (q?.openMedia?.src) {
    //       media.push({ ...q?.openMedia, index })
    //     }
    //     if (q?.endMedia?.src) {
    //       media.push({ ...q?.endMedia, index })
    //     }
    //     if (q?.question?.src) {
    //       media.push({ src: q?.question?.src, index })
    //     }
    //     if (q.type === 'ans_images') {
    //       q.question.answers.forEach(ans => {
    //         media.push({ ...ans?.ansMedia, ...ans, index })
    //       })
    //     }
    //   })
    //   return media
    // },
    assetsArray(game) {
      const regExp = /mp4|mp3/gm
      // let media = this.findMedia(game.questions, 'Media')

      let media = this.findAllByKey(game, 'Media')

      if (game?.setting?.logo?.src) {
        media.push(game.setting.logo)
      }
      media = _.uniqBy(media, 'src')
      media = media?.filter(m => m.src)
      media = media?.filter(m => !m.src?.match(/www.youtube/gm))
      media = media?.map(m => ({
        ...m,
        progress: 0,
        name: m.src.split('assets/')[1],
        type: m.src.match(regExp) ? 'video' : 'image',
      }))
      return media
    },
    async downloadZip(fileName) {
      const content = await this.zip.generateAsync(
        {
          type: 'blob',
        },
        metadata => {
          this.$store.commit('loading/setValue', metadata.percent.toFixed(2))
        }
      )
      saveAs(content, fileName + '.zip')
    },
    extractClapErrorHe(e) {
      if (e && e.response && e.response.data && e.response.data.clapErr) {
        let errObj = e.response.data
        return errObj.he
      } else {
        return 'משהו השתבש'
      }
    },
  },
}
export default downloadMixins
// async main(game, gameToJSON) {

//   this.zip.file("data.json", JSON.stringify(gameToJSON));

//   let { image, video } = this.assetsArray(game);

//   const imageResult = await this.fetchAssets(image, imagePath, "image");
//   const videoResult = await this.fetchAssets(video, videoPath, "video");
//   console.log({ imageResult, videoResult });
//   console.log(game);
//   await this.downloadZip(game.name);
// },
