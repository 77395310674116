<template>
  <v-container
    fill-height
    grid-list-md
    bg
    text-xs-center
    class="d-flex align-center flex-column center-all"
  >
  
    <div class="d-flex align-center flex-column center-all">
      <v-row>
        <v-col md="6" sm="6"
          ><v-btn
            :to="{ name: 'MyGame' }"
            min-width="400"
            min-height="100"
            v-text="$t('route.MyGame')"
        /></v-col>
      </v-row>
      <v-row>
        <v-col
          ><v-btn
            class="success"
            @click="goGamePage"
            min-width="400"
            min-height="100"
            v-text="$t('route.CreateGame')"
        /></v-col>
      </v-row>
    </div>
    <user-handler
      v-if="userDetailsDialog"
      v-model="userDetailsDialog"
      :user="user"
    />
  </v-container>
</template>

<script>
import SwalMixins from '@/mixins/swal'
import UserHandler from '@/components/handler/UserHandler.vue'

export default {
  components: { UserHandler },
  name: 'UserDashboard',
  mixins: [SwalMixins],

  methods: {
    async goGamePage() {
      try {
        const { isConfirmed, value } = await this.createInputSwaldata({
          title: this.$t('games.name'),
        })
        if (!isConfirmed) return
        this.$store.commit('loading/active', true)
        await this.$store.dispatch('game/store', value)
        await this.$router.push({
          name: 'CreateGame',
          params: { isNew: true, id: this.$store.getters.game._id },
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.userDetails
    },
    userDetailsDialog: {
      get() {
        return this.$store.getters.userDetailsDialog
      },
      set(v) {
        return this.$store.commit('auth/userDetailsDialog', v)
      },
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
  },
}
</script>
