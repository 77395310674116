const triviaQuestion = {
  setting: {
    type: 'trivia',
    allowChangeVote: false,
    slideStartVoting: true,
    playAfterClicking: false,
    exitGame: false,
    correctlyAnsweredBefore: false,
    firstClicker: false,
    answerIsSequenceClicks: false,
    skipAfterClick: false,
    fullscreen: false,
    scoringReduction: {
      active: false,
      seconds: '',
      score: '',
    },
    automaticSkip: {
      active: false,
      seconds: '',
    },
    slidBackgroundMedia: {
      src: '',
      format: '',
    },
  },
}
export default triviaQuestion
