<template>
  <div>
    <v-btn v-if="!hideButton" color="primary" @click="openDialog">{{
      $t('gallery.buttonText')
    }}</v-btn>

    <v-dialog v-model="dialogVisible" max-width="800">
      <v-overlay :value="loading" color="transparent">
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>{{ $t('gallery.title') }}</v-card-title>
        <v-toolbar dense class="mb-4">
          <v-text-field
            v-model="searchQuery"
            append-icon="mdi-magnify"
            :label="$t('gallery.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(image, index) in images"
              :key="index"
              cols="12"
              sm="6"
              md="4"
            >
              <v-card @click="selectImage(image)">
                <v-img
                  :src="image.url"
                  height="200"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  :class="{ 'selected-image': chosenImage === image }"
                >
                  <v-card-title class="image-title rounded-lg">{{
                    image.title
                  }}</v-card-title>
                  <v-icon
                    class="open-dialog-icon"
                    color="white"
                    @click.stop="openImageDialog(image)"
                  >
                    mdi-eye
                  </v-icon>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
          <v-pagination
            class="mt-6"
            v-model="currentPage"
            :total-visible="perPage"
            :length="Math.ceil(imageCount / perPage)"
            color="primary"
          ></v-pagination>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="imageUploadVisible = 1">{{
            $t('gallery.upload')
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="chosenImage"
            text
            color="primary"
            @click="closeAndChoose"
            >{{ $t('gallery.choose') }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeDialog">{{
            $t('gallery.close')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="imageDialogVisible"
      v-if="selectedImage.url"
      max-width="800"
    >
      <v-card>
        <v-img :src="selectedImage.url" height="600"></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="closeImageDialog">סגור</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="badImageSnackbar" :timeout="1500">
      {{ $t('gallery.badImage') }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          {{ $t('gallery.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <upload-image v-model="imageUploadVisible" />
  </div>
</template>

<script>
import UploadImage from './UploadImage.vue'
import { debounce } from 'lodash'
export default {
  props: {
    value: {
      default: false,
    },
    hideButton: { default: false },
  },
  components: { UploadImage },
  data() {
    return {
      badImageSnackbar: false,
      uploadImages: [],
      cropData: null,
      outputWidth: 200,
      outputHeight: 200,
      selectedImage: '',
      imageUploadVisible: false,
      currentPage: 1,
      loading: false,
      searchQuery: '',
      chosenImage: null,
      imageDialogVisible: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    perPage() {
      return this.$store.getters.perPage
    },
    images() {
      return this.$store.getters.images
    },
    imageCount() {
      return this.$store.getters.imagesCount
    },
  },
  methods: {
    closeAndChoose() {
      const folder = process.env.VUE_APP_CLOUD_FOLDER
      const { url } = this.chosenImage
      const fileName = folder + url.split(folder)[1]
      if (!fileName) {
        return (this.badImageSnackbar = true)
      }
      const [src, format] = fileName.split('.')

      const chosenImageData = { 
        src,
        format,
      }

      this.$emit('chosen', chosenImageData)

      this.dialogVisible = false
      this.chosenImage = false
    },
    selectImage(image) {
      if (this.chosenImage === image) {
        this.chosenImage = null
        return
      }
      this.chosenImage = image
    },
    async addData() {
      this.loading = true // Show the loading overlay
      await this.$store.dispatch('image/index', {
        page: this.currentPage,
        perPage: this.perPage,
        search: this.searchQuery,
      })

      this.loading = false // Hide the loading overlay
    },
    async openDialog() {
      this.dialogVisible = true // Show the dialog
    },
    closeDialog() {
      this.chosenImage = null
      this.dialogVisible = false
    },
    openImageDialog(image) {
      this.selectedImage = image
      this.imageDialogVisible = true
    },
    closeImageDialog() {
      this.imageDialogVisible = false
    },
  },
  async mounted() {
    await this.addData()
    this.debouncedAddData = debounce(this.addData, 500)
    this.debouncedAddDataSearch = debounce(this.addData, 1000)
  },
  watch: {
    currentPage() {
      this.debouncedAddData()
    },
    searchQuery() {
      this.debouncedAddDataSearch()
    },
  },
}
</script>

<style scoped>
.v-card-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 16px;
}

.v-card-actions {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px;
}

.v-dialog .v-card {
  margin-bottom: 0;
}

.v-dialog {
  overflow-y: visible !important;
}
.image-title {
  display: inline;
  font-size: 16px;
  font-weight: bold;
  color: black;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.4);
}
.selected-image {
  border: 3px solid #00bcd4;
}

.open-dialog-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 24px;
  cursor: pointer;
}
</style>
