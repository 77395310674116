import axios from 'axios'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    count: 0,
    images: [],
    perPage: 6,
  },
  getters: {
    images: state => state.images,
    imagesCount: state => state.count,
    perPage: state => state.perPage,
  },
  mutations: {
    'image/set': (state, payload) => {
      state.images = payload
    },
    'image/unshiftPop': (state, payload) => {
      state.images.unshift(payload)
      if (state.images.length > state.perPage) state.images.pop()
    },
    'image/setCount': (state, payload) => {
      state.count = payload
    },
  },
  actions: {
    //fetch all games
    'image/index': async (context, { page, perPage, search } = {}) => {
      try {
        //url query params
        const params = new URLSearchParams()
        params.append('page', page)
        params.append('perPage', perPage)
        params.append('searchText', search || '')
        const {
          data: { images, count },
        } = await axios.get(`/image?${params.toString()}`)
        context.commit('image/setCount', count)
        context.commit('image/set', images)
        return images
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
