const surveyQuestion = {
  setting: {
    type: 'survey',
    allowChangeVote: false,
    slideStartVoting: true,
    playAfterClicking: false,
    correctlyAnsweredBefore: false,
    answerIsSequenceClicks: false,
    skipAfterClick: false,
    automaticSkip: {
      active: false,
      seconds: '',
    },
    slidBackgroundMedia: {
      src: '',
      format: '',
    },
  },
}
export default surveyQuestion
