<template>
  <div>
    <div v-for="(asset, i) in assetsList" :key="asset.name">
      <download-row
        :asset="asset"
        :index="i + 1"
        @success="success"
        @removeAsset="removeAsset"
      />
    </div>
  </div>
</template>
<script>
import downloadMixins from '@/mixins/downloadGame'
import DownloadRow from './DownloadRow.vue'

export default {
  name: 'DownloadsLoader',
  mixins: [downloadMixins],
  components: { DownloadRow },
  props: { game: { type: Object, default: () => ({}) } },
  data() {
    return {
      assetsList: [],
    }
  },
  methods: {
    progressColor(progress) {
      if (progress < 30) {
        return 'red'
      } else if (progress < 60) {
        return 'pink'
      } else if (progress < 90) {
        return 'yellow'
      } else {
        return 'light-green accent-3'
      }
    },
    success(item) {
      this.$emit('success', item)
    },
    removeAsset(item) {
      this.assetsList = this.assetsList.filter(a => a.name !== item.name)
      this.$emit('setAssetsLength', this.assetsList.length)
    },
  },
  async mounted() {
    this.assetsList = this.game.assets
    this.$emit('setAssetsLength', this.assetsList.length)
  },
}
</script>
<style lang=""></style>
