import Vue from 'vue'
import Vuex from 'vuex'
import moduleList from '@/store/vuexModules'

Vue.use(Vuex)

const modulesCreator = () => {
  let modules = {}
  moduleList.forEach(m => {
    modules[m] = require('./modules/' + m).default
  })
  return modules
}
let vuexData = {
  state: {
    title: 'questions-creator',
  },
  getters: {
    title: state => state.title,
  },
  mutations: {
    'title/set': (state, payload) => {
      document.title = payload
      state.title = payload
    },
  },
  modules: modulesCreator(),
}
export default new Vuex.Store(vuexData)
