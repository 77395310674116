<template>
  <div>
    <my-dialog
      :title="$t('setting.setting')"
      v-model="openModal"
      :isLoading="isLoading"
    >
      <template v-slot:content>
        <v-container grid-list-xs>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="gameName"
                auto-grow
                color="deep-purple"
                :label="$t('setting.gameName')"
                rows="1"
                autofocus
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="form.titleThroughoutGame"
                auto-grow
                color="deep-purple"
                :label="$t('setting.textAllGame')"
                rows="1"
                autofocus
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <cloudinary
                :publicId="form.gameMedia.src"
                :buttonText="$t('setting.image')"
                @save="v => saveMedia(v, 'gameMedia')"
                @loading="loading"
                :format="form.gameMedia.format"
                :deleteText="$t('generics.remove')"
                onlyVideo
                onlyImage
              />
            </v-col>
            <v-col cols="3">
              <cloudinary
                :publicId="form.logo.src"
                :buttonText="$t('setting.logo')"
                @save="v => saveMedia(v, 'logo')"
                @loading="loading"
                :format="form.logo.format"
                :deleteText="$t('generics.remove')"
                onlyVideo
                onlyImage
              />
            </v-col>
            <v-col cols="3">
              <cloudinary
                :publicId="form.triviaMedia.src"
                :buttonText="$t('setting.triviaMedia')"
                @save="v => saveMedia(v, 'triviaMedia')"
                @loading="loading"
                :format="form.triviaMedia.format"
                :deleteText="$t('generics.remove')"
                onlyVideo
                onlyImage
              />
            </v-col>
            <v-col cols="4">
              <cloudinary
                :publicId="form.winnersListMedia.src"
                :buttonText="$t('setting.winnersListMedia')"
                @save="v => saveMedia(v, 'winnersListMedia')"
                @loading="loading"
                :format="form.winnersListMedia.format"
                :deleteText="$t('generics.remove')"
                onlyVideo
                onlyImage
              />
            </v-col>
            <v-col cols="4" class="d-flex justify-center align-center">
              <select-update-format
                :src="form.winnersMedia.src"
                :btnText="$t('setting.winnersMedia')"
                @save="v => saveMedia(v, 'winnersMedia')"
                @loading="loading"
                :format="form.winnersMedia.format"
                :deleteText="$t('generics.remove')"
                onlyVideo
                onlyImage
              />
            </v-col>
          </v-row>
          <setting-sound
            @loading="loading"
            v-model="form"
            :soundList="soundList"
          />
          <v-row>
            <v-col cols="3" class="d-flex" justify="start">
              <div class="mx-2">
                <my-color-picker
                  v-model="form.mainColor"
                  :label="$t('games.setting.mainColor')"
                />
              </div>
              <div class="mx-2">
                <my-color-picker
                  v-model="form.secondaryColor"
                  :label="$t('games.setting.secondaryColor')"
                />
              </div>
            </v-col>
            <v-col cols="4">
              {{ $t('setting.ansIsNumber') }}
              <v-radio-group v-model="form.ansIsNumber">
                <v-row>
                  <v-col>
                    <v-radio
                      :value="true"
                      key="isNumber"
                      :label="$t('generics.numbers')"
                    />
                  </v-col>
                  <v-col>
                    <v-radio
                      :value="false"
                      key="isWord"
                      :label="$t('generics.words')"
                    />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-checkbox
                :label="$t('setting.cancelWinnersList')"
                v-model="cancelWinnersList"
                hide-details
                class="mt-0 pt-0"
              />
              <div class="d-flex mx-2" v-if="!cancelWinnersList">
                <span class="ml-2">
                  {{ $t('setting.showWinnersListAfter') }}
                </span>
                <vue-numeric-input
                  v-model="form.showWinnersListAfter"
                  :min="1"
                  :step="1"
                  width="80px"
                  controlsType="updown"
                  align="center"
                />
              </div>
              <!-- <v-text-field
                v-if="!cancelWinnersList"
                v-model="form.showWinnersListAfter"
                :label="$t('setting.showWinnersListAfter')"
                type="number"
                min="1"
                :rules="[(v) => v > 0 || $t('errors.validate.validNumber')]"
                :disabled="cancelWinnersList"
              /> -->
            </v-col>
            <v-col cols="4">
              {{ $t('setting.multiWinners') }}
              <vue-numeric-input
                v-model="form.multiWinners"
                :min="1"
                :max="5"
                :step="1"
                width="80px"
                controlsType="updown"
                align="center"
              />
              <!-- <v-text-field
                v-model="form.multiWinners"
                :label="$t('setting.multiWinners')"
                type="number"
                min="1"
                :rules="[(v) => v > 0 || $t('errors.validate.validNumber')]"
              /> -->
            </v-col>
          </v-row>
          <!-- <v-col>
              <v-checkbox
                v-model="form.allowChangeVote"
                :label="$t('אפשר לשנות את ההצבעה')"
                rows="1"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="form.slideStartVoting"
                :label="$t('התחל בהצבעה')"
                rows="1"
              ></v-checkbox>
            </v-col> -->
          <!-- <v-col cols="4"> -->
          <!-- v-text="$t('setting.')" -->
          <!-- <v-checkbox
                :label="$t('setting.')"
                v-model=""
              />
            </v-col>
            <v-col cols="3" v-if="">
              <v-text-field
                v-model="form.maxParticipants"
                :label="$t('setting.maxParticipants')"
                type="number"
                min="1"
                :rules="[(v) => v > 0 || $t('errors.validate.validNumber')]"
              />
            </v-col> -->
          <!-- </v-row> -->
          <v-row v-if="err">
            <v-col class="error--text text-center">{{ err }}</v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="$t('generics.save')"
          @click="save"
        />
        <v-btn
          text
          color="primary"
          v-text="$t('generics.cancel')"
          @click="cancel"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from '../MyDialog.vue'
import Cloudinary from '@/components/buttons/Cloudinary.vue'
import MyColorPicker from '../MyColorPicker.vue'
import SelectUpdateFormat from '@/components/buttons/SelectUploadFormat.vue'
import _ from 'lodash'
import SettingSound from './component/SettingSound.vue'

export default {
  name: 'setting-handler',
  components: {
    MyDialog,
    Cloudinary,
    MyColorPicker,
    SelectUpdateFormat,
    SettingSound,
  },
  props: {
    value: Boolean,
    setting: Object,
  },
  data: () => ({
    isLoading: false,
    gameName: '',
    soundList: [
      'winnersMediaSound',
      'winnersListMediaSound',
      'genericMediaSound',
      'timerMediaSound',
      'inShowAnsMediaSound',
    ],
    form: {
      multiWinners: 5,
      showWinnersListAfter: '5',
      titleThroughoutGame: '',
      mainColor: '#ffffffff',
      secondaryColor: '#00000070',
      ansIsNumber: true,
      gameMedia: {
        src: '',
        format: '',
      },
      logo: {
        src: '',
        format: '',
      },
      triviaMedia: {
        src: '',
        format: '',
      },
      winnersListMedia: {
        src: '',
        format: '',
      },
      winnersMedia: {
        src: '',
        format: '',
      },
      winnersMediaSound: {
        src: '',
        format: '',
      },
      winnersListMediaSound: {
        src: '',
        format: '',
      },
      genericMediaSound: {
        src: '',
        format: '',
      },
      timerMediaSound: {
        src: '',
        format: '',
      },
      inShowAnsMediaSound: {
        src: '',
        format: '',
      },
    },
    cancelWinnersList: false,
    err: null,
  }),
  computed: {
    game() {
      return this.$store.getters.game
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    saveMedia(value, name) {
      this.form[name].src = value.public_id
      this.form[name].format = value.format
    },
    loading(v) {
      this.isLoading = v
    },
    async save() {
      try {
        await this.$store.dispatch('game/update', {
          ...this.game,
          name: this.gameName,
          setting: { ...this.form },
        })

        this.openModal = false
      } catch (e) {
        return this.createErr(this.$t('errors.global'))
      }
    },
    cancel() {
      this.openModal = false
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
      return
    },
  },
  mounted() {
    if (this.game?.setting) {
      this.form = _.cloneDeep({ ...this.form, ...this.game.setting })
    }
    if (!this.form?.showWinnersListAfter) {
      this.cancelWinnersList = true
    }
    this.gameName = this.game?.name
  },
  watch: {
    cancelWinnersList() {
      if (this.cancelWinnersList) {
        this.form.showWinnersListAfter = ''
      }
      if (!this.cancelWinnersList) {
        this.form.showWinnersListAfter = '5'
      }
    },
  },
}
</script>
